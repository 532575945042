<template>
  <v-row no-gutters class="ma-0">
   <v-col class="text-center grey-background pt-6 px-0 pb-3" :class="[{'ml-n12' : !$store.state.base.isExpandNavigationDrawer}, `${!['AccountMembers', 'AccountGroups'].includes($route.name) ? 'pagination' : 'paginationMember'}`]" :style="$store.state.base.isExpandNavigationDrawer ? `${!['AccountMembers', 'AccountGroups'].includes($route.name) ? 'margin-left: -150px' : ''}` : ''">
    <v-col cols="12" class="py-0">
      <div :style="`position: relative; margin-left: calc(100% - ${$store.state.base.isExpandNavigationDrawer ? '450' : '335'}px)`">
        <slot name="main" />
      </div>
    </v-col>
    <div class="d-block mt-n5">
      <v-btn class="subtitle-2" @click="previous" :disabled="page === 1" color="secondary" text :ripple="false">
        <v-icon class="mr-3">mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-icon class="mx-5" color="secondary" size="8 ">mdi-circle</v-icon>
      <v-btn class="subtitle-2 font-weight-medium pa-0 btnPage" color="primary" icon small :ripple="false">{{ page }}</v-btn>
      <v-icon class="mx-5" color="secondary" size="8 ">mdi-circle</v-icon>
      <v-btn class="subtitle-2" @click="next" :disabled="count < 20" color="secondary" text :ripple="false">
        Siguiente <v-icon class="ml-3">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-col>
</v-row>
</template>

<script>
import ListViewMixin from '@/mixins/ListViewMixin'

export default {
  mixins: [
    ListViewMixin
  ],
  props: {
    titleShowAll: {
      type: String,
      default: 'Ver todos los documentos'
    },
    redirect: {
      type: String,
      default: null
    },
    count: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  watch: {
    currentPage: {
      handler (val) {
        if (val > 1) this.page = val
      },
      inmediate: true,
      deep: true
    }
  },
  methods: {
    previous () {
      if (this.page > 1) this.page -= 1
    },
    next () {
      if (this.count >= 20) this.page += 1
    }
  }
}
</script>